import React, { useEffect, useState, useRef, useCallback, useLayoutEffect } from 'react';
import { sendMessage, newChat, getImagePathForAgent, getAgentDetails, deleteChat, getAgentTeamDetails, newTeamChat } from './messaging';
import * as signalR from "@microsoft/signalr";
import { MessagePackHubProtocol } from "@microsoft/signalr-protocol-msgpack";
import { List, Input, Button, Image, Spin, Popconfirm, Upload, message } from 'antd';
import { AudioOutlined, LoadingOutlined, SoundOutlined, HeatMapOutlined, DeleteOutlined, PlusOutlined, StopOutlined, BugOutlined, UploadOutlined, CloseOutlined, PlayCircleOutlined, PauseCircleOutlined, PauseOutlined, CaretRightOutlined, OrderedListOutlined } from '@ant-design/icons';
import ConfigurationManager from './components/ConfigurationManager';
import VoiceChatButton from './components/VoiceChatButton';
import TaskListPanel from './components/TaskListPanel';
import MessageItem from './components/MessageItem';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';



const { Search } = Input;

//Wake on keyword
//https://github.com/Picovoice/porcupine/blob/master/LICENSE
//https://github.com/linto-ai/WebVoiceSDK/blob/master/tests/with-bundler/index.js -- looks good

export default function SydneyChatHub({ playbackManager, tokenManager, mic, agentId, agentType, loadedChatId, loadedChatMessages, showDebugInfo, costsUpdated, updateChatList }) {
    const [connection, setConnection] = useState(null);
    const [latestUserInput, setLatestInput] = useState(null);
    const [messages, setMessages] = useState([]);
    const [logMessages, setlogMessages] = useState([]);
    const [sortedMessages, setSortedMessages] = useState([]);   //Needed to sort the messages by timestamp, as they were ariving out of order.    
    const [chatId, setChatId] = useState(0);
    const [speakerOn, setSpeakerOn] = useState(true);
    const [showListPanel, setShowListPanel] = useState(false);
    const [aiThinking, setAIThinking] = useState(false);
    const isProcessing = useRef(false);
    const [isPaused, setIsPaused] = useState(false);
    const [isStopped, setIsStopped] = useState(false);
    const [listeningOn, setListeningOn] = useState(false);
    const [hasAgent, setHasAgent] = useState(false);

    const [chatCost, setChatCost] = useState(null);
    const [commandsOn, setCommandsOn] = useState(false);

    const queue = useRef([]);
    const myRef = useRef(null);
    const [continuousListeningOn, setContinuousListeningOn] = useState(false);
    const [currentAgentDetail, setCurrentAgentDetail] = useState(null);
    const userInputBox = useRef(null);
    const [showDebugConsole, setShowDebugConsole] = useState(false);
    const [uploadedImages, setUploadedImages] = useState([]);
    const MAX_TICKS = 9223372036854775806n;
    const chatIdRef = useRef(chatId);
    const [muteUserMessages, setMuteUserMessages] = useState(false);
    const [uploadedAudioBuffer, setUploadedAudioBuffer] = useState(null);
    const [uploadedAudioUrl, setUploadedAudioUrl] = useState(null);

    const offsetRef = useRef(0);
    const isProcessingWav = useRef(false);
    const [paused, setPaused] = useState(false);

    const [currentPlaybackPercentage, setCurrentPlaybackPercentage] = useState(0);



    //have a variable to hold the previouslyprossed agent, so we can detect when it changes
    const prevAgent = useRef(null);

    useEffect(() => {
        setShowDebugConsole(false);
    }, []);

    useEffect(() => {
        console.defaultLog = console.log.bind(console);
        console.defaultError = console.error.bind(console);
        console.defaultWarn = console.warn.bind(console);
        console.defaultDebug = console.debug.bind(console);
        console.defaultInfo = console.info.bind(console);

        console.log = function () {
            console.defaultLog.apply(console, arguments);

            setlogMessages(
                logMessages => [...logMessages, Array.from(arguments)]
            );
        }
        console.info = function () {
            console.defaultLog.apply(console, arguments);

            setlogMessages(
                logMessages => [...logMessages, Array.from(arguments)]
            );
        }

        console.error = function () {
            // default &  console.error()
            console.defaultError.apply(console, arguments);
            // new & array data
            setlogMessages(
                logMessages => [...logMessages, Array.from(arguments)]
            );
        }

        console.warn = function () {
            console.defaultWarn.apply(console, arguments);

            setlogMessages(
                logMessages => [...logMessages, Array.from(arguments)]
            );
        }
        console.debug = function () {
            // default &  console.debug()
            console.defaultDebug.apply(console, arguments);
            setlogMessages(
                logMessages => [...logMessages, Array.from(arguments)]
            );
        }

        return () => {
            console.debug = console.defaultDebug;
            console.warn = console.defaultWarn;
            console.error = console.defaultError;
            console.log = console.defaultLog;
        };
    }
        , []);

    useEffect(() => {
        setChatId(loadedChatId);
    }, [loadedChatId]);

    useEffect(() => {
        chatIdRef.current = chatId;
    }, [chatId]);


    const handleMessage = useCallback(async (messageChatId, message) => {
        var javascriptMessage = Object.keys(message).reduce((acc, key) => {
            const camelCaseKey = key.charAt(0).toLowerCase() + key.slice(1);
            acc[camelCaseKey] = message[key];
            return acc;
        }, { chatId: messageChatId });

        queue.current.push(javascriptMessage);

    }, []);



    useEffect(() => {
        if (tokenManager !== null) {
            const newConnection = new signalR.HubConnectionBuilder()
                .withAutomaticReconnect()
                .withUrl(`${ConfigurationManager.getApiUrl()}chatHub`, {
                    accessTokenFactory: async () => {
                        var aadToken = tokenManager.getAADToken()
                        return aadToken;
                    }
                })
                .withHubProtocol(new MessagePackHubProtocol())
                .configureLogging(signalR.LogLevel.Debug)
                .build();

            setConnection(newConnection);
        }
        else {
        }
    }, [tokenManager]);

    useEffect(() => {
        if (connection) {
            if (connection.state === 'Disconnected' && !connection.connectionStarted) {
                connection.start()
                    .then(() => console.log('SignalR Connected'))
                    .catch(console.error);
            }
        }
    }, [connection, handleMessage, chatId]);

    useEffect(() => {
        function handleCosts(costsForChatId, costs) {
            const currentChatId = chatIdRef.current;

            if (costsForChatId === currentChatId) {
                var cost = Number.parseFloat(costs.CostForChat).toFixed(3);
                setChatCost(cost);
                costsUpdated(costs);
            }
            else {
                if (costsForChatId === null) {
                    setChatCost(null);
                    costsUpdated(costs);
                }
            }
        };

        function handleUpdateChatList(newChatList) {
            updateChatList(newChatList);
        }

        function handleCompletedChatTurns() {
            setAIThinking(false);
        }

        function handleChatStateChanged(chatId, state) {

        }


        if (connection) {
            connection.on('ReceiveMessage', handleMessage);
            connection.on('ReceiveCosts', handleCosts);
            connection.on('ReceiveAllChats', handleUpdateChatList);
            connection.on('CompletedChatTurn', handleCompletedChatTurns);
            connection.on('ChatStateChanged', handleChatStateChanged);

            return () => {
                connection.off('ReceiveMessage', handleMessage);
                connection.off('ReceiveCosts', handleCosts);
                connection.off('ReceiveAllChats', handleUpdateChatList);
                connection.off('CompletedChatTurn', handleCompletedChatTurns);
                connection.off('ChatStateChanged', handleChatStateChanged);
            };
        }
    }, [connection, costsUpdated, handleMessage, updateChatList]);

    const dedupeMessages = useCallback((messages) => {
        var dedupedMessages = [];
        messages.forEach((message) => {
            var found = false;
            dedupedMessages.forEach((dedupedMessage) => {
                if (String(dedupedMessage.id) === String(message.id) && message.id !== null) {
                    found = true;
                }
            });

            if (!found) {
                dedupedMessages.push(message);
            }
        });

        return dedupedMessages;
    }, []);

    const integrateMessages = useCallback((currentMessages, newMessages) => {
        //clone the messages array
        var messagesCopy = [...currentMessages];


        for (var newMessageIndex = 0; newMessageIndex < newMessages.length; newMessageIndex++) {
            var newMessage = newMessages[newMessageIndex];

            var index = locateMessageInOldList(currentMessages, newMessage);

            if (index !== null) {
                var existingMessage = messagesCopy[index];

                //if it's got more info than the existing message, then replace it
                if (existingMessage != null && newMessage != null && (existingMessage.friendlySummary === null || existingMessage.friendlySummary === undefined || existingMessage.friendlySummary === "") &&
                    (newMessage.friendlySummary !== null && newMessage.friendlySummary !== undefined && newMessage.friendlySummary !== "")) {
                    messagesCopy.splice(index, 1, newMessage);
                }

            }
            else {
                messagesCopy = messagesCopy.concat(newMessage);
            }
        }

        function locateMessageInOldList(currentMessages, newMessage) {
            var syncIdIndex = null;
            for (var index = 0; index < currentMessages.length; index++) {
                var message = currentMessages[index];
                if (String(message.id).toString() === String(newMessage.id).toString()) {
                    syncIdIndex = index;
                    break;
                }
            }

            return syncIdIndex;
        }



        messagesCopy = dedupeMessages(messagesCopy);

        return (messagesCopy);
    }, [dedupeMessages]);

    function htmlEnc(s) {
        return s.replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/'/g, '&#39;')
            .replace(/"/g, '&quot;');
    }

    const processQueue = useCallback(() => {
        try {
            if (queue.current.length > 0 && chatId !== null && chatId !== undefined) {
                const message = queue.current[0];

                queue.current = queue.current.slice(1);

                if (message.chatId == null || message.chatId.toString() !== chatId.toString()) {
                    processQueue();
                    return;
                }

                setMessages(prevMessages => integrateMessages(prevMessages, [message]));



                switch (message.roleName.toLowerCase()) {
                    case "assistant":
                        //setSearchInProgress(false);
                        break;
                    case "command":
                        if (message.content.toLowerCase() === htmlEnc("<performsearch>")) {
                            //setSearchInProgress(true);

                            if (speakerOn) {
                                playbackManager.toVoice("Searching", function () {
                                    processQueue();
                                });
                            }
                        }
                        break;
                    default:
                        processQueue();
                        break;
                }

            }
        }
        catch (e) {
            console.error("Error when processing: " + e);
        }
        finally {
            isProcessing.current = false;
        }
    }, [chatId, integrateMessages, playbackManager, speakerOn]);


    const processQueueRef = useRef(processQueue);
    useEffect(() => {
        processQueueRef.current = processQueue;
    }, [processQueue]);

    useEffect(() => {
        let intervalId;
        if (connection) {
            intervalId = setInterval(() => {
                if (!isProcessing.current && chatId !== null && agentId !== null) {
                    isProcessing.current = true;
                    processQueueRef.current(chatId);
                }
            }, 100);
        }
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [continuousListeningOn, chatId, agentId, connection, processQueue]);


    useEffect(() => {
        if (currentAgentDetail && currentAgentDetail.Description) {
            const introMessage = {
                id: 'intro-message',
                timestamp: 0,
                content: currentAgentDetail.Description,
                roleName: 'intro',
            };
            const sortedMessagesCopy = [...messages].sort((a, b) => a.timeStamp - b.timeStamp);

            // Modify here to filter out user messages if muteUserMessages is true  
            const filteredMessages = muteUserMessages
                ? sortedMessagesCopy.filter(
                    (message) => message.roleName.toLowerCase() !== 'user'
                )
                : sortedMessagesCopy;

            setSortedMessages([introMessage, ...filteredMessages]);
        } else {
            // Similarly modify here  
            const sortedMessagesCopy = [...messages].sort((a, b) => a.timeStamp - b.timeStamp);

            const filteredMessages = muteUserMessages
                ? sortedMessagesCopy.filter(
                    (message) => message.roleName.toLowerCase() !== 'user'
                )
                : sortedMessagesCopy;

            setSortedMessages(filteredMessages);
        }
    }, [currentAgentDetail, messages, muteUserMessages]);


    const doCancelSynth = useCallback(async () => {
        if (playbackManager) {
            await playbackManager.stop();
        }
    }, [playbackManager]);

    function removeTags(htmlString, tag) {
        // Create a new DOM element to hold the HTML string
        var container = document.createElement('div');
        container.innerHTML = htmlString;

        // Find all tag elements in the container
        var tags = container.querySelectorAll(tag);

        // Loop through the img elements and remove them from the container
        for (var i = 0; i < tags.length; i++) {
            tags[i].parentNode.removeChild(tags[i]);
        }

        // Return the remaining HTML as a string
        return container.innerHTML;
    }

    const doShowReady = (state) => {
        setListeningOn(state);
        if (state === false) {
            //wakeWordRef.current.resume();
        }
    }

    const processAndSendAudio = useCallback(
        async (arrayBuffer) => {
            if (!isProcessingWav.current) {
                isProcessingWav.current = true;

                const audioContext = new (window.AudioContext || window.webkitAudioContext)();
                let audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
                const targetSampleRate = 24000; // The sample rate expected by the backend  
                let resampledBuffer = audioBuffer;
                if (audioBuffer.sampleRate !== targetSampleRate) {
                    resampledBuffer = await resampleAudioBuffer(audioBuffer, targetSampleRate);
                }
                let monoBuffer = resampledBuffer;
                if (resampledBuffer.numberOfChannels > 1) {
                    monoBuffer = downmixToMono(resampledBuffer);
                }
                var duration = monoBuffer.duration;
                if (duration === 0) {
                    duration = 1;
                }

                setCurrentPlaybackPercentage(0);


                const channelData = monoBuffer.getChannelData(0);
                const totalSamples = channelData.length;
                const chunkSizeInSeconds = 3; // Define your chunk duration in seconds  
                const chunkSize = chunkSizeInSeconds * monoBuffer.sampleRate;



                const processChunk = async () => {
                    if (offsetRef.current >= totalSamples) {
                        // Finished processing  
                        isProcessingWav.current = false;
                        return;
                    }
                    if (pausedRef.current || !isProcessingWav.current) {
                        // Paused, do not schedule next chunk  
                        return;
                    }
                    const chunkData = channelData.slice(offsetRef.current, offsetRef.current + chunkSize);
                    const chunkBuffer = audioContext.createBuffer(
                        1,
                        chunkData.length,
                        monoBuffer.sampleRate
                    );
                    chunkBuffer.copyToChannel(chunkData, 0, 0);
                    await playChunk(chunkBuffer, audioContext);
                    const pcmData = floatTo16BitPCM(chunkData);
                    connection.invoke("ReceiveAudioChunk", chatId, pcmData).catch(err =>
                        console.error(err)
                    );
                    offsetRef.current += chunkSize;

                    var newTime = (offsetRef.current / monoBuffer.sampleRate);
                    newTime = Math.round(newTime * 100) / 100;
                    var newPercentage = Math.round((newTime / duration) * 100);

                    if (newPercentage !== currentPlaybackPercentage) {
                        setCurrentPlaybackPercentage(newPercentage);
                    }


                    // Schedule the next chunk  
                    setTimeout(processChunk, 0);
                };

                // Expose processChunk for resuming  
                processChunkRef.current = processChunk;

                // Start processing  
                processChunk();
            }
        },
        [connection, chatId, currentPlaybackPercentage]
    );


    const processChunkRef = useRef(null);
    const pausedRef = useRef(paused);

    // Keep pausedRef updated  
    useEffect(() => {
        pausedRef.current = paused;
    }, [paused]);

    // Resume processing when unpaused  
    useEffect(() => {
        if (!paused && isProcessingWav.current) {
            // Resume processing  
            if (processChunkRef.current) {
                processChunkRef.current();
            }
        }
    }, [paused]);


    const playChunk = (audioBuffer, audioContext) => {
        return new Promise((resolve) => {
            const source = audioContext.createBufferSource();
            source.buffer = audioBuffer;
            source.connect(audioContext.destination);
            source.start();

            source.onended = () => {
                resolve();
            };
        });
    };

    function floatTo16BitPCM(buffer) {
        const output = new Int16Array(buffer.length);

        for (let i = 0; i < buffer.length; i++) {
            let s = Math.max(-1, Math.min(1, buffer[i]));
            output[i] = s < 0 ? s * 0x8000 : s * 0x7FFF;
        }
        return output;
    }

    function downmixToMono(buffer) {
        const length = buffer.length;
        const data = new Float32Array(length);
        const channels = buffer.numberOfChannels;
        const channelData = [];
        for (let i = 0; i < channels; i++) {
            channelData.push(buffer.getChannelData(i));
        }
        for (let i = 0; i < length; i++) {
            let sum = 0;
            for (let j = 0; j < channels; j++) {
                sum += channelData[j][i];
            }
            data[i] = sum / channels;

        }

        var audioBuffer = new AudioBuffer({
            length: data.length,
            sampleRate: buffer.sampleRate,
            numberOfChannels: 1,
        });

        audioBuffer.copyToChannel(data, 0);

        return audioBuffer;
    }

    function resampleAudioBuffer(audioBuffer, targetSampleRate) {
        return new Promise((resolve) => {
            const channels = audioBuffer.numberOfChannels;
            const duration = audioBuffer.duration;
            const offlineContext = new OfflineAudioContext(channels, duration * targetSampleRate, targetSampleRate);
            const bufferSource = offlineContext.createBufferSource();
            bufferSource.buffer = audioBuffer;
            bufferSource.connect(offlineContext.destination);
            bufferSource.start(0);
            offlineContext.startRendering().then((renderedBuffer) => {
                resolve(renderedBuffer);
            });
        });
    }


    const doSendMessage = useCallback(async (message) => {

        if (connection) {
            //get the timestamp of the last message

            var timestamp = 0;
            if (sortedMessages.length > 0) {
                const filteredList = sortedMessages.filter(message => message.roleName !== "waiting");
                const lastMessage = filteredList[filteredList.length - 1];

                if (lastMessage !== null && lastMessage !== undefined) {
                    timestamp = lastMessage.timestamp;

                    if (timestamp === null || timestamp === undefined || isNaN(timestamp)) {
                        timestamp = 0;
                    }
                }
                else
                    timestamp = 0;
            }

            setMessages(
                messages => [...messages, { id: null, timestamp: timestamp + 1, content: message, roleName: "user", images: uploadedImages }]
            );

            setLatestInput("");
            setUploadedImages([]);

            setMessages(
                messages => [...messages, { id: null, timestamp: MAX_TICKS, content: "", roleName: "waiting" }]
            );

            await doCancelSynth();
            try {
                if (message !== null && message !== undefined && message !== "") {

                    const isTeam = (agentType === 'team');

                    if (!isTeam) {
                        setAIThinking(true);
                        var chatResponse = await sendMessage(await tokenManager.getAADToken(), agentId, chatId, message, uploadedImages, isTeam);


                        if (chatId !== chatResponse.chatId) {
                            setChatId(chatResponse.chatId);
                        }

                        if (chatResponse && chatResponse.messages.length > 0) {
                            setMessages(currentMessages => integrateMessages(currentMessages.filter(message => message.roleName !== "waiting"), chatResponse.messages));
                            setMessages(currentMessages => currentMessages.filter(message => message.id !== null));

                            if (chatResponse.messages[chatResponse.messages.length - 1].roleName === "assistant") {
                                if (speakerOn) {
                                    var newMessage = chatResponse.messages[chatResponse.messages.length - 1];
                                    if (newMessage.success) {
                                        var replyText = newMessage.content;
                                        replyText = removeTags(replyText, 'img');
                                        replyText = removeTags(replyText, 'searchids');


                                        await playbackManager.toVoice(replyText, async () => {
                                            if (continuousListeningOn) {
                                                await doCancelSynth();
                                                await mic(doShowReady, doSendMessage, agentId, chatId)
                                            }
                                        });

                                    }
                                }
                            }
                        }
                        setAIThinking(false);
                    }
                    else {                     
                        setAIThinking(true);
                        await connection.invoke("RecieveText", chatId, message)
                    }
                }

                // If there's an uploaded audio buffer, process and send it  
                if (uploadedAudioBuffer) {
                    setPaused(false);
                    await processAndSendAudio(uploadedAudioBuffer);
                    // Clear the uploaded audio after sending  
                    setUploadedAudioBuffer(null);
                    setUploadedAudioUrl(null);
                }

                setMessages(currentMessages => currentMessages.filter(message => message.roleName !== "waiting"));
            }
            catch (e) {
                console.error("Error sending message: " + e);
                setMessages(currentMessages => currentMessages.filter(message => message.roleName !== "waiting"));
            }
        }

    }, [agentType, connection, sortedMessages, doCancelSynth, uploadedImages, MAX_TICKS, uploadedAudioBuffer, tokenManager, agentId, chatId, integrateMessages, speakerOn, playbackManager, continuousListeningOn, mic, processAndSendAudio]);

    // handle what happens on key press
    const handleKeyPress = useCallback((event) => {
        if (event.altKey === true && (event.key === 't' || event.key === 'T')) {
            startListening();
        }

        async function startListening() {
            if (agentId !== null && chatId !== null) {
                queue.current.push({ message: "Yeah?", roleName: 'assistant' });

                setContinuousListeningOn(true);

                mic(doShowReady, doSendMessage, agentId, chatId).catch(error => {
                    console.error('Error starting the microphone:', error);
                });
            }
        }
    }, [chatId, doSendMessage, mic, agentId]);



    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [handleKeyPress]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [handleKeyPress]);


    const voiceComplete = useCallback(async () => {
        processQueue();

        if (continuousListeningOn) {
            await doCancelSynth();
            await mic(doShowReady, doSendMessage, agentId, chatId)
        }
    }, [processQueue, continuousListeningOn, doCancelSynth, mic, doSendMessage, agentId, chatId]);

    const onNewChat = useCallback(async () => {
        setContinuousListeningOn(false);
        await doCancelSynth();
        if (agentId !== undefined && agentId !== null) {
            setChatId(null);
            setChatCost(null);
            setMessages([{ id: null, timestamp: MAX_TICKS, content: "", roleName: "waiting" }]);

            let newChatData;

            if (agentType === 'team') {
                // Call the new team chat endpoint  
                newChatData = await newTeamChat(await tokenManager.getAADToken(), agentId);
            } else {
                // Call the existing agent chat endpoint  
                newChatData = await newChat(await tokenManager.getAADToken(), agentId);
            }

            if (newChatData !== undefined && newChatData !== null) {
                const chatId = newChatData.chatId;
                setChatId(chatId);
                setMessages(currentMessages =>
                    integrateMessages(currentMessages.filter(message => message.roleName !== "waiting"), newChatData.messages)
                );

                if (speakerOn && newChatData.messages.length >= 0) {
                    const lastMessage = newChatData.messages[newChatData.messages.length - 1];
                    if (lastMessage && lastMessage.roleName === "assistant") {
                        await playbackManager.toVoice(lastMessage.content, voiceComplete);
                    }
                }
            } else {
                setMessages([]);
                setChatId(null);
            }
        }
    }, [doCancelSynth, agentId, MAX_TICKS, agentType, tokenManager, speakerOn, integrateMessages, playbackManager, voiceComplete]);

    const onAgentChange = useCallback(async (currentAgent) => {
        if (prevAgent.current !== currentAgent) {
            prevAgent.current = currentAgent;
            if (currentAgent !== null && currentAgent !== undefined) {

                const fetchData = async (currentAgent) => {
                    setMessages([]);
                    await onNewChat(currentAgent);
                };

                fetchData(agentId);

                const getImagePath = async (currentAgent) => {
                    return await getImagePathForAgent(await tokenManager.getAADToken(), currentAgent);
                };

                getImagePath(currentAgent)
                    .then(path => {
                        //setImagePath(path)
                    }
                    );
            }
            setHasAgent(currentAgent !== null);
        }
    }, [onNewChat, agentId, tokenManager]);

    useEffect(() => {
        onAgentChange(agentId);
    }, [onAgentChange, agentId]);


    useEffect(() => {
        setMessages(loadedChatMessages);
    }, [loadedChatMessages]);

    useEffect(() => {
        if (tokenManager && agentId !== undefined && agentId !== null) {
            tokenManager.getAADToken().then(async (aadToken) => {
                let agentDetails = null;
                if (agentType === 'team') {
                    // Fetch the team details to get the lead agent's ID  
                    const teamDetails = await getAgentTeamDetails(aadToken, agentId);
                    if (teamDetails) {
                        agentDetails = teamDetails.leadAgent;
                    } else {
                        console.error('Failed to get team details');
                        return;
                    }
                } else {
                    agentDetails = await getAgentDetails(aadToken, agentId);
                }
                setCurrentAgentDetail(agentDetails);
            }).catch(error => {
                console.error('Error fetching agent details:', error);
            });
        }
    }, [tokenManager, agentId, agentType]);

    const onCancelSynth = async () => {
        setContinuousListeningOn(false);

        doCancelSynth();
    };

    const onSendMessage = async () => {
        await doSendMessage(latestUserInput);
    };

    function onUpdateUserInputField(e) {
        setLatestInput(e.target.value);
    }

    async function onDeleteChat() {
        var aadToken = await tokenManager.getAADToken();
        await deleteChat(aadToken, agentId, chatId);
        setChatId(null);
    }

    //get the user agent
    const userAgent = navigator.userAgent.toLowerCase();
    const iPad = ((userAgent.match(/(iPad)/) /* iOS pre 13 */ || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) /* iPad OS 13 */));

    const listeningIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;


    const handleUploadChange = useCallback((info) => {
        if (info.file.status === 'done' || info.file.status === 'uploading') {
            const fileType = info.file.type;
            const reader = new FileReader();
            reader.onload = (e) => {
                if (fileType.startsWith('image/')) {
                    // Handle image files  
                    setUploadedImages((prevImages) => {
                        if (!prevImages.some(image => image.name === info.file.name)) {
                            return [...prevImages, { name: info.file.name, data: e.target.result }];
                        }
                        return prevImages;
                    });
                } else if (fileType === 'audio/wav') {
                    // Handle audio files  
                    setUploadedAudioBuffer(e.target.result);
                    setUploadedAudioUrl(URL.createObjectURL(info.file.originFileObj));
                }
            };
            if (fileType.startsWith('image/')) {
                reader.readAsDataURL(info.file.originFileObj);
            } else if (fileType === 'audio/wav') {
                reader.readAsArrayBuffer(info.file.originFileObj);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            }
        }
    }, []);

    const uploadProps = {
        name: 'file',
        multiple: true,
        accept: 'image/*,audio/wav',
        showUploadList: false, // We'll manage the upload list ourselves  
        beforeUpload: (file) => {
            const isSupportedType = file.type.startsWith('image/') || file.type === 'audio/wav';
            if (!isSupportedType) {
                message.error('You can only upload image or WAV audio files!');
            }
            return isSupportedType || Upload.LIST_IGNORE;
        },
        onChange: handleUploadChange,
    };

    const isGPT4oRealtimeEnabled = useCallback(() => {
        if (currentAgentDetail !== null && currentAgentDetail !== undefined) {
            if (currentAgentDetail.realtimeChatEnabled) {
                return true;
            }
        }

        return false;
    }, [currentAgentDetail]);


    
    const pauseChat = useCallback(async (message) => {
        setIsPaused(true);
        try {
            await connection.invoke("PauseChat", chatId);
        }
        catch (ex) {
            console.error(ex);
        }
 
    }, [chatId, connection]);

    const resumeChat = useCallback(async (message) => {
        setIsPaused(false);
        await connection.invoke("ResumeChat", chatId);
    }, [chatId, connection]);

    const stopChat = useCallback(async (message) => {

        setIsStopped(true);
        await connection.invoke("StopChat", chatId);
    }, [chatId, connection]);

    var searchSuffix = (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {!iPad && isGPT4oRealtimeEnabled() &&
                <VoiceChatButton connection={connection} chatId={chatId} tokenManager={tokenManager} speakerOn={speakerOn} disabled={aiThinking} />
            }
            {!iPad && !isGPT4oRealtimeEnabled() &&
                <Button disabled={!hasAgent || aiThinking} onClick={
                    async (e) => {
                        await doCancelSynth();
                        var text = await mic(doShowReady, null, agentId, chatId)
                        await doSendMessage(text);
                    }
                }

                    className="speak-button" >
                    <AudioOutlined
                        style={{
                            fontSize: 20,
                            color: '#1677ff',
                        }}
                    />
                </Button>
            }

            <Upload disabled={!hasAgent} {...uploadProps} className="upload-img-button">
                <Button disabled={!hasAgent || aiThinking} icon={<UploadOutlined />} style={{
                    fontSize: 20,
                    color: '#1677ff',
                }} />
            </Upload>

            {aiThinking && (
                <div className="chat-controls">
                    {!isStopped && !isPaused && (
                        <Button icon={<PauseOutlined />} onClick={pauseChat}>Pause</Button>
                    )}
                    {isPaused && (
                        <Button icon={<CaretRightOutlined />} onClick={resumeChat}>Resume</Button>
                    )}
                    {!isStopped && (
                        <Button icon={<StopOutlined />} danger onClick={stopChat}>Stop</Button>
                    )}
                </div>)
            }

            {isProcessingWav.current && (
                <div className="audio-controls">
                    {paused ? (
                        <Button icon={<PlayCircleOutlined />} onClick={handlePlayPause}>
                            Play
                        </Button>
                    ) : (
                        <Button icon={<PauseCircleOutlined />} onClick={handlePlayPause}>
                            Pause
                        </Button>
                    )}
                    <Button icon={<StopOutlined />} onClick={handleStop}>
                        Stop
                    </Button>
                </div>
            )}
        </div>
    );


    // Remove image handler
    const removeImage = (index) => {
        setUploadedImages((prevImages) => prevImages.filter((_, i) => i !== index));
    };

    useLayoutEffect(() => {
        var objDiv = document.getElementById("chatMessageContainer");

        if (objDiv != null) {
            objDiv.scrollBy(0, 100000);
        }
    });




    var chatbox = document.getElementById("chatbox");
    var chatMessageContainer = document.getElementById("chatMessageContainer");

    if (chatbox) {
        // Put the body relative
        document.body.style.position = 'relative';
        let marginTop = parseInt(window.getComputedStyle(document.body).marginTop);

        // My toolbar (in my case, a div with an input inside to make a chat)
        chatbox.style.position = 'absolute';

        // Events (touchmove on mobile, because the scroll event doesn't work well)
        window.addEventListener("scroll", resizeHandler);
        window.addEventListener("touchmove", resizeHandler);
        window.visualViewport.addEventListener("resize", resizeHandler);

        resizeHandler();


        function resizeHandler() {
            var chatboxHeight = 160;
            if (uploadedImages.length > 0 || uploadedAudioUrl) {
                chatboxHeight += 120;
            }

            chatbox.style.top = (window.scrollY + window.visualViewport.height - marginTop - chatboxHeight) + 'px';
            chatbox.style.right = "0px";
            chatbox.style.left = "60px";
            chatbox.style.bottom = "0px";

            //set the window Y scroll position to 0
            window.scrollTo(0, 0);
            chatMessageContainer.style.height = (window.visualViewport.height - marginTop - (chatboxHeight + 30)) + 'px';
        }
    }

    function handlePlayPause() {
        setPaused(!paused);
    }

    function handleStop() {
        setPaused(true);
        isProcessingWav.current = false;
        setCurrentPlaybackPercentage(0);
    }

    return (
        <div className="chathub-control">
            <div className="left-vertical-control-bar">
                <div className="left-side-button chat-delete">
                    <Popconfirm
                        placement="right"
                        title="Are you sure you want to delete this chat?"
                        description="Delete chat"
                        onConfirm={onDeleteChat}
                        okText="Yes"
                        cancelText="No"
                        okButtonProps={{ fontSize: 20 }}
                    >
                        <Button
                            type="text"
                            icon={<DeleteOutlined style={{ fontSize: 26 }} />}

                            disabled={chatId === null || chatId === -1 || !hasAgent}
                            style={{
                                width: 60,
                                height: 60,
                            }}
                        />
                    </Popconfirm>
                </div>

                <div className="left-side-button new-chat">
                    <Popconfirm
                        placement="right"
                        title="Are you sure you want to start a new chat?"
                        description="New chat"
                        onConfirm={onNewChat}
                        okText="Yes"
                        cancelText="No"
                        okButtonProps={{ fontSize: 20 }}
                    >
                        <Button
                            type="text"
                            icon={<PlusOutlined style={{ fontSize: 26 }} />}
                            disabled={!hasAgent}
                            style={{
                                width: 60,
                                height: 60,
                            }}
                        />
                    </Popconfirm>
                </div>


                <div className="left-side-button sound-on-off">
                    <Button
                        type="text"
                        icon={speakerOn ? <SoundOutlined style={{ color: 'black', fontSize: 26 }} /> : <SoundOutlined style={{ color: 'grey', fontSize: 26 }} />}
                        onClick={() => setSpeakerOn(!speakerOn)}
                        style={{
                            color: '#ffffff',
                            width: 60,
                            height: 60,
                        }}
                    />
                </div>

                <div className="left-side-button list-panel-open">
                    <Button
                        type="text"
                        icon={<OrderedListOutlined style={{ color: 'black', fontSize: 26 }} />}
                        onClick={() => setShowListPanel(!showListPanel)}
                        style={{
                            color: '#ffffff',
                            width: 60,
                            height: 60,
                        }}
                    />
                </div>


                {agentId && showDebugInfo &&
                    <>
                        <div className="left-side-button debug-on-off">
                            <Button
                                type="text"
                                icon={commandsOn ? <HeatMapOutlined style={{ color: 'black', fontSize: 26 }} /> : <HeatMapOutlined style={{ color: 'grey', fontSize: 26 }} />}
                                onClick={() => setCommandsOn(!commandsOn)}
                                style={{
                                    color: '#ffffff',
                                    width: 60,
                                    height: 60,
                                }}
                            />
                        </div>
                    </>


                }


                {
                    <div className="left-side-button debug-console">
                        <Button
                            type="text"
                            icon={showDebugConsole ? <BugOutlined style={{ color: 'black', fontSize: 26 }} /> : <BugOutlined style={{ color: 'grey', fontSize: 26 }} />}
                            onClick={() => setShowDebugConsole(!showDebugConsole)}
                            style={{
                                color: '#ffffff',
                                width: 60,
                                height: 60,
                            }}
                        />
                    </div>
                }



                <div className="left-side-button stop-playback">
                    <Button
                        type="text"
                        icon={<StopOutlined style={{ fontSize: 26 }} />}
                        disabled={chatId === null || chatId === -1 || !hasAgent}
                        onClick={onCancelSynth}
                        style={{
                            width: 60,
                            height: 60,
                        }}
                    />
                </div>

                {/* Mute User Messages Button */}
                <div className="left-side-button mute-user-messages">
                    <Button
                        type="text"
                        icon={
                            muteUserMessages ? (
                                <EyeInvisibleOutlined style={{ color: 'black', fontSize: 26 }} />
                            ) : (
                                <EyeOutlined style={{ color: 'grey', fontSize: 26 }} />
                            )
                        }
                        onClick={() => setMuteUserMessages(!muteUserMessages)}
                        style={{
                            width: 60,
                            height: 60,
                        }}
                    />
                </div>
            </div>
            <Spin className="listening" spinning={listeningOn} indicator={listeningIcon} >
                <div className="chat-messages" id="chatMessageContainer">
                    {chatId != null &&
                        <>
                            <List
                                itemLayout="horizontal"
                                dataSource={sortedMessages}
                                renderItem={(message, index) => (
                                    <MessageItem
                                        key={message.id}
                                        message={message}
                                        index={index}
                                        tokenManager={tokenManager}
                                        commandsOn={commandsOn}
                                        playbackManager={playbackManager}
                                    />
                                )}
                            />
                        </>
                    }
                    <div ref={myRef}  >
                    </div>
                </div>
            </Spin>



            <div className="chat-hub-input-area" >

                <div className="chatbox" id="chatbox">


                    {/* Thumbnails Section */}
                    {((uploadedImages.length > 0) || uploadedAudioUrl) && (
                        <div
                            style={{
                                padding: '10px',
                                borderBottom: '1px solid #f0f0f0',
                                display: 'flex',
                                overflowX: 'auto',
                                background: '#fafafa',
                            }}
                        >

                            {uploadedAudioUrl && (
                                <div style={{ position: 'relative', marginRight: '10px' }}>
                                    {/* <div className="wav-tile">*/}
                                    <Image src="img/wav.png" alt="WAV File" width={100} height={100} style={{ objectFit: 'cover', borderRadius: '4px' }} />
                                    {/*<span>{uploadedAudioUrl.name}</span>*/}
                                    {/* </div>*/}

                                    <Button
                                        type="text"
                                        icon={<CloseOutlined />}
                                        size="small"
                                        onClick={() => { setUploadedAudioUrl(null); setUploadedAudioBuffer(null); }}
                                        style={{
                                            position: 'absolute',
                                            top: '5px',
                                            right: '5px',
                                            background: 'rgba(255, 255, 255, 0.7)',
                                        }}
                                    />
                                </div>
                            )}

                            {uploadedImages.map((img, index) => (
                                <div key={index} style={{ position: 'relative', marginRight: '10px' }}>
                                    <Image
                                        src={img.data}
                                        alt={`uploaded-${index}`}
                                        width={100}
                                        height={100}
                                        style={{ objectFit: 'cover', borderRadius: '4px' }}
                                    />

                                    <Button
                                        type="text"
                                        icon={<CloseOutlined />}
                                        size="small"
                                        onClick={() => removeImage(index)}
                                        style={{
                                            position: 'absolute',
                                            top: '5px',
                                            right: '5px',
                                            background: 'rgba(255, 255, 255, 0.7)',
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    )}


                    <Search ref={userInputBox} placeholder="Please ask your question" onChange={onUpdateUserInputField} value={latestUserInput} disabled={!hasAgent || (aiThinking && !isPaused) || isGPT4oRealtimeEnabled()} enterButton="Ask" size="large"
                        onSearch={onSendMessage} suffix={searchSuffix} className={(iPad ? "userInput userInput-iPad" : "userInput userInput-desktop")} />
                </div>

                {
                    agentId && currentAgentDetail && currentAgentDetail.showChatCost &&
                    <div><span className="chat-cost">Chat Cost: ${chatCost}</span>

                        {isProcessingWav.current && (
                            <span className="audio-progress">
                                Playback {currentPlaybackPercentage}%
                            </span>
                        )}

                    </div>
                }
            </div>
            {
                showDebugConsole &&
                <List itemLayout="horizontal" className="debugConsole" dataSource={logMessages}
                    renderItem={(item) => (
                        <List.Item>
                            {item}
                        </List.Item>
                    )}>
                </List>
            }

            <TaskListPanel connection={connection} chatId={chatId} tokenManager={tokenManager} visible={showListPanel} />
        </div>
    );


}