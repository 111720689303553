import axios from 'axios';
import ConfigurationManager from './components/ConfigurationManager';
/*import PlaybackManager from './PlaybackManager';*/

export async function getUserProfile(token) {
    const instance = axios.create({
        baseURL: ConfigurationManager.getApiUrl(),
        timeout: 15000,
        headers: { 'Authorization': `Bearer ${token}` }
    });
    const res = await instance.get('/User/GetMe');
    return res.data; // Array of SecurityGroup objects
}

export async function getAllSecurityGroups(token) {
    const instance = axios.create({
        baseURL: ConfigurationManager.getApiUrl(),
        timeout: 15000,
        headers: { 'Authorization': `Bearer ${token}` }
    });
    const res = await instance.get('/Group/GetAll');
    return res.data; // Array of SecurityGroup objects
};

export async function searchUsers(token, query) {
    const instance = axios.create({
        baseURL: ConfigurationManager.getApiUrl(),
        timeout: 15000,
        headers: { 'Authorization': `Bearer ${token}` }
    });

    const res = await instance.get('/User/SearchUsers', { params: { query } });
    return res.data; // Array of User objects
}

export async function getSecurityGroup(token, groupId) {
    const instance = axios.create({
        baseURL: ConfigurationManager.getApiUrl(),
        timeout: 15000,
        headers: { 'Authorization': `Bearer ${token}` }
    });
    const res = await instance.get('/Group/GetGroup', { params: { groupId } });
    return res.data; // Single SecurityGroup object
}

export async function getSecurityGroupsForUser(token) {
    const instance = axios.create({
        baseURL: ConfigurationManager.getApiUrl(),
        timeout: 15000,
        headers: { 'Authorization': `Bearer ${token}` }
    });

    const res = await instance.get('/Group/GetGroupsForUser');
    return res.data; 
}

export async function createSecurityGroup(token, group) {
    const instance = axios.create({
        baseURL: ConfigurationManager.getApiUrl(),
        timeout: 15000,
        headers: { 'Authorization': `Bearer ${token}` }
    });
    const res = await instance.post('/Group/Create', group);
    return res.data; // Created group
}

export async function updateSecurityGroup(token, group) {
    const instance = axios.create({
        baseURL: ConfigurationManager.getApiUrl(),
        timeout: 15000,
        headers: { 'Authorization': `Bearer ${token}` }
    });
    const res = await instance.put('/Group/Update', group);
    return res.data; // Updated group
}

export async function deleteSecurityGroup(token, groupId) {
    const instance = axios.create({
        baseURL: ConfigurationManager.getApiUrl(),
        timeout: 15000,
        headers: { 'Authorization': `Bearer ${token}` }
    });
    await instance.delete('/Group/Delete', { params: { groupId } });
}

export async function addUserToGroup(token, groupId, userId) {
    const instance = axios.create({
        baseURL: ConfigurationManager.getApiUrl(),
        timeout: 15000,
        headers: { 'Authorization': `Bearer ${token}` }
    });
    await instance.post('/Group/AddUser', null, { params: { groupId, userId } });
}

export async function removeUserFromGroup(token, groupId, userId) {
    const instance = axios.create({
        baseURL: ConfigurationManager.getApiUrl(),
        timeout: 15000,
        headers: { 'Authorization': `Bearer ${token}` }
    });
    await instance.post('/Group/RemoveUser', null, { params: { groupId, userId } });
}

export async function getImagePathForAgent(token, agentId) {
    const realToken = token;
    try {
        const instance = axios.create({
            baseURL: ConfigurationManager.getApiUrl(),
            timeout: 15000,
            headers: { 'Authorization': `Bearer ${realToken}` }
        });

        var url = `/chat/GetImagePathFor?&agentId=${encodeURIComponent(agentId)}`;
        const res = await instance.get(url);

        return res.data;
    } catch (err) {
        console.error(err.message);

        if (err.response != null && err.response.data != null)
            console.log(err.response.data);

        return;
    }
}

export async function getListDataFromAPI(token, chatId, id) {
    const realToken = token;

    const instance = axios.create({
        baseURL: ConfigurationManager.getApiUrl(),
        timeout: 15000,
        headers: { 'Authorization': `Bearer ${realToken}` }
    });
    var url = `/list/list?chatId=${chatId}&id=${id}`;
    const res = await instance.get(url);

    // Assuming the API returns an array of TaskDTO objects  
    return res.data;
};

export async function getListsFromAPI(token, chatId) {
    const realToken = token;
    if (chatId !== null && chatId !== undefined) {
        const instance = axios.create({
            baseURL: ConfigurationManager.getApiUrl(),
            timeout: 15000,
            headers: { 'Authorization': `Bearer ${realToken}` }
        });
        var url = `/list/lists?chatId=${chatId}`;
        const res = await instance.get(url);

        // Assuming the API returns an array of ListDTO objects  
        return res.data;
    }
    else
        return null;
};

export async function newChat(token, agentId) {
    const realToken = token;
    try {
        const instance = axios.create({
            baseURL: ConfigurationManager.getApiUrl(),
            timeout: 60000,
            headers: { 'Authorization': `Bearer ${realToken}` }
        });

        var url = `/chat/NewConversation?agentId=${agentId}`;
        const res = await instance.get(url);

        return res.data;
    } catch (err) {
        console.error(err.message);

        if (err.response != null && err.response.data != null)
            console.log(err.response.data);

        return;
    }
}

export async function newTeamChat(token, teamId) {
    const realToken = token;
    try {
        const instance = axios.create({
            baseURL: ConfigurationManager.getApiUrl(),
            timeout: 60000,
            headers: { 'Authorization': `Bearer ${realToken}` }
        });
        const url = `/chat/NewTeamConversation?teamId=${teamId}`;
        const res = await instance.get(url);
        return res.data;
    } catch (err) {
        console.error(err.message);
        if (err.response && err.response.data)
            console.log(err.response.data);
        return;
    }
}


export async function saveAgentTeam(token, agentData) {
    const realToken = token;
    try {
        const instance = axios.create({
            baseURL: ConfigurationManager.getApiUrl(),
            timeout: 30000,
            headers: { 'Authorization': `Bearer ${realToken}` }
        });

        await instance.post('/team/SaveTeam', agentData);

    } catch (err) {
        console.error(err.message);

        if (err.response != null && err.response.data != null)
            console.log(err.response.data);

        return;
    }
}

export async function getAgentTeams(token) {
    const realToken = token;
    try {
        const instance = axios.create({
            baseURL: ConfigurationManager.getApiUrl(),
            timeout: 60000,
            headers: { 'Authorization': `Bearer ${realToken}` }
        });
        const res = await instance.get('/team/AllCurrentTeams');
        return res.data;
    } catch (err) {
        console.error(err.message);
        if (err.response != null && err.response.data != null)
            console.log(err.response.data);
        return;
    }
}

export async function getAgentTeamDetails(aadToken, teamId) {
    try {
        const instance = axios.create({
            baseURL: ConfigurationManager.getApiUrl(),
            timeout: 15000,
            headers: { 'Authorization': `Bearer ${aadToken}` },
        });
        var url = `/team/GetTeam?id=${teamId}`;

        if (teamId === null || teamId === undefined) {
            url = `/team/GetTeam?id=`;
        }

        const res = await instance.get(url);
        return res.data;
    } catch (err) {
        console.error(err.message);
        throw err;
    }
}

export async function deleteChat(token, agentId, chatId) {
    const realToken = token;
    try {
        const instance = axios.create({
            baseURL: ConfigurationManager.getApiUrl(),
            timeout: 15000,
            headers: { 'Authorization': `Bearer ${realToken}` }
        });

        var url = `/chat/DeleteChat?agentId=${agentId}&chatid=${chatId}`;
        const res = await instance.delete(url);

        return res.data;
    } catch (err) {
        console.error(err.message);

        if (err.response != null && err.response.data != null)
            console.log(err.response.data);

        return;
    }
}

export async function sendMessage(token, agentId, chatId, message, messageImages, isTeam) {
    const realToken = token;
    try {
        const instance = axios.create({
            baseURL: ConfigurationManager.getApiUrl(),
            timeout: 60000,
            headers: { 'Authorization': `Bearer ${realToken}` }
        });

        if (message === null || message === undefined)
            message = '';

        var reply = await instance.post('/chat/UpdateChat', {
            agentId: agentId,
            chatId: chatId,
            input: message,
            images: messageImages,
            isTeam: isTeam
        }, {
            params: {
            }
        });

        return reply.data;
    } catch (err) {
        console.error(err.message);

        if (err.response != null && err.response.data != null)
            console.log(err.response.data);

        return;
    }
}

export async function sendAgentUpdate(token, agentData) {
    const realToken = token;
    try {
        const instance = axios.create({
            baseURL: ConfigurationManager.getApiUrl(),
            timeout: 30000,
            headers: { 'Authorization': `Bearer ${realToken}` }
        });

        await instance.post('/agent/Save', agentData);

    } catch (err) {
        console.error(err.message);

        if (err.response != null && err.response.data != null)
            console.log(err.response.data);

        return;
    }
}

export async function getAgents(token) {
    const realToken = token;

    try {
        const instance = axios.create({
            baseURL: ConfigurationManager.getApiUrl(),
            timeout: 60000,
            headers: { 'Authorization': `Bearer ${realToken}` }
        });

        const res = await instance.get('/agent/AllCurrent');

        return res.data;
    } catch (err) {
        console.error(err.message);

        if (err.response != null && err.response.data != null)
            console.log(err.response.data);

        return;
    }
}

export async function getCheckClaims(token) {
    const realToken = token;

    try {
        const instance = axios.create({
            baseURL: ConfigurationManager.getApiUrl(),
            timeout: 15000,
            headers: { 'Authorization': `Bearer ${realToken}` }
        });

        const res = await instance.get('/debugInfo/Claims');

        return res.data;
    } catch (err) {
        console.error(err.message);

        if (err.response != null && err.response.data != null)
            console.log(err.response.data);

        return;
    }
}


export async function getAllChats(token, agentId) {
    const realToken = token;

    try {
        const instance = axios.create({
            baseURL: ConfigurationManager.getApiUrl(),
            timeout: 15000,
            headers: { 'Authorization': `Bearer ${realToken}` }
        });

        const res = await instance.get(`/chat/GetAllChats?agentId=${agentId}`);

        return res.data;
    } catch (err) {
        console.error(err.message);

        if (err.response != null && err.response.data != null)
            console.log(err.response.data);

        return;
    }
}

export async function getConversation(token, chatId, agentId) {
    const realToken = token;

    try {
        const instance = axios.create({
            baseURL: ConfigurationManager.getApiUrl(),
            timeout: 15000,
            headers: { 'Authorization': `Bearer ${realToken}` }
        });

        const res = await instance.get(`/chat/GetExistingConversation?chatId=${chatId}&agentId=${agentId}`);

        return res.data;
    } catch (err) {
        console.error(err.message);

        if (err.response != null && err.response.data != null)
            console.log(err.response.data);

        return;
    }
}

export async function getAgentDetails(aadToken, agentId, version = null) {

    try {
        const instance = axios.create({
            baseURL: ConfigurationManager.getApiUrl(),
            timeout: 15000,
            headers: { 'Authorization': `Bearer ${aadToken}` }
        });

        let url = `/agent/get?agentId=${encodeURIComponent(agentId)}`;

        if (agentId === null || agentId === undefined) {
            url = `/agent/get?name=`;
        }

        if (version !== null) {
            url += `&version=${version}`;
        }

        const res = await instance.get(url);

        return (res.data)
    } catch (err) {
        console.error(err.message);

        if (err.response != null && err.response.data != null)
            console.log(err.response.data);

        return;
    }
}

export async function getAgentVersions(aadToken, agentId) {
    try {
        const instance = axios.create({
            baseURL: ConfigurationManager.getApiUrl(),
            timeout: 15000,
            headers: { 'Authorization': `Bearer ${aadToken}` },
        });
        const url = `/agent/getversions?agentId=${agentId}`;
        const res = await instance.get(url);
        return res.data; // Should be an array of versions  
    } catch (err) {
        console.error(err.message);
        throw err;
    }
}
export async function getInferenceOptions(aadToken) {

    try {
        const instance = axios.create({
            baseURL: ConfigurationManager.getApiUrl(),
            timeout: 15000,
            headers: { 'Authorization': `Bearer ${aadToken}` }
        });

        var url = `/Inference/get`;
        const res = await instance.get(url);

        return (res.data)
    } catch (err) {
        console.error(err.message);

        if (err.response != null && err.response.data != null)
            console.log(err.response.data);

        return;
    }
}

export async function triggerImportOfBlobDataFile(aadToken, agentId, searchAgentName) {

    try {
        const instance = axios.create({
            baseURL: ConfigurationManager.getApiUrl(),
            timeout: 15000,
            headers: { 'Authorization': `Bearer ${aadToken}` }
        });

        var url = `/SearchIndex/ImportBlobDataFile?agentId=${agentId}&searchAgentName=${encodeURIComponent(searchAgentName)}`;
        await instance.get(url);
    } catch (err) {
        console.error(err.message);

        if (err.response != null && err.response.data != null)
            console.log(err.response.data);

        return;
    }
}


export async function getInferenceChat(token, debugLogId) {
    const realToken = token;

    try {
        const instance = axios.create({
            baseURL: ConfigurationManager.getApiUrl(),
            timeout: 15000,
            headers: { 'Authorization': `Bearer ${realToken}` }
        });

        const res = await instance.get(`/DebugInfo/Inference?id=${debugLogId}`);

        return res.data;
    } catch (err) {
        console.error(err.message);

        if (err.response != null && err.response.data != null)
            console.log(err.response.data);

        return;
    }
}