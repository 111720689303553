import { Modal, Button, Spin, List } from 'antd';
import React, { useState, useEffect } from 'react';
import { getUserProfile, getSecurityGroupsForUser } from '../messaging';



const UserInfo = ({ tokenManager, visible, onClose }) => {

    const [showUserInfo, setShowUserInfo] = useState(false); // controls the modal visibility
    const [currentUserProfile, setCurrentUserProfile] = useState(null);
    const [currentUserGroups, setCurrentUserGroups] = useState([]);


    // Fetch user info when the modal is opened
    useEffect(() => {
        async function fetchUserInfo() {
            setShowUserInfo(visible);

            if (!showUserInfo) return;
            try {
                const token = await tokenManager.getAADToken();
                const userProfile = await getUserProfile(token); 
                const userGroups = await getSecurityGroupsForUser(token);
                setCurrentUserProfile(userProfile || null);
                setCurrentUserGroups(userGroups || []);
            } catch (err) {
                console.error('Error fetching user data:', err);
            }
        }
        fetchUserInfo();
    }, [visible, tokenManager, showUserInfo]);


    return (
        <Modal
            title="User Information"
            visible={showUserInfo}
            onCancel={onClose}
            footer={[
                <Button key="close" onClick={onClose}>
                    Close
                </Button>
            ]}
        >
            {(!currentUserProfile && !currentUserGroups.length) ? (
                <Spin tip="Loading user info..." />
            ) : (
                <>
                    {currentUserProfile && (
                        <div style={{ marginBottom: '1em' }}>
                            <h3>Profile</h3>
                            <p><b>ID:</b> {currentUserProfile.id}</p>
                            <p><b>Name:</b> {currentUserProfile.displayName}</p>
                            {(currentUserProfile.mail) && <p><b>Email:</b> {currentUserProfile.mail}</p>}
                        </div>
                    )}
                    {currentUserGroups && currentUserGroups.length > 0 && (
                        <div>
                            <h3>Groups</h3>
                            <List
                                size="small"
                                bordered
                                dataSource={currentUserGroups}
                                renderItem={(grp) => (
                                    <List.Item>
                                        {grp.displayName} ({grp.uniqueId})
                                    </List.Item>
                                )}
                            />
                        </div>
                    )}
                </>
            )}
        </Modal>)
}

export default UserInfo;