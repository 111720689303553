import React, { useState, useEffect, useCallback } from 'react';
import { Input, Upload, Select, Button, Form, Spin, Modal, Space, InputNumber } from 'antd';
import { sendAgentUpdate, getAgentDetails, getInferenceOptions, getAgentTeamDetails, saveAgentTeam, getAgents, getAgentVersions, getAllSecurityGroups } from '../messaging';
import { Checkbox } from '../../node_modules/antd/es/index';
import ADGroupsDropdown from './PickADGroup';
import ToolConfigPanel from './ToolConfigPanel';
import { UploadOutlined } from '@ant-design/icons';
import set from 'lodash/set';

const { TextArea } = Input;


const AgentConfigurator = ({ msalInstance, agentId, tokenManager, disabled, onSaved, onCanceled, type, mode, visible }) => {
    const [groupOptions, setGroupOptions] = useState([]);
    const [team, setTeam] = useState(null);
    const [agent, setAgent] = useState(null);
    const [availableAgents, setAvailableAgents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [busyMessage, setBusyMessage] = useState("Loading...");
    const [inferenceOptions, setInferenceOptions] = useState([]);
    const inputRefs = React.useRef({});
    const [versions, setVersions] = useState([]);
    const [selectedVersion, setSelectedVersion] = useState(null);
    const [isReadOnly, setIsReadOnly] = useState(false);


    const loadAgentForEditing = useCallback(async (tokenManager, agentId, setAgent, setInferenceOptions, setLoading, version = null) => {
        const aadToken = await tokenManager.getAADToken();
        let agentData = null;
        let teamData = null;

        if (type === 'team') {
            teamData = await getAgentTeamDetails(aadToken, agentId, version);
            agentData = teamData.leadAgent;
            //agentData = await getAgentDetails(aadToken, teamData.leadAgent.Id); // Use leadAgent.Id to get agent details  
        } else {
            agentData = await getAgentDetails(aadToken, agentId, version);
        }

        // If a specific version is selected, set the form to read-only  
        if (version !== null) {
            setIsReadOnly(true);
        } else {
            setIsReadOnly(false);
        }

        if (mode === 'add') {
            agentData.Id = null;
            if (teamData !== null)
                teamData.Id = null;
        }

        setAgent(agentData);
        setTeam(teamData);
        getInferenceOptions(aadToken).then((data) => {
            var options = data.map((item) => ({ value: item.model, label: item.model, data: item }));
            setInferenceOptions(options);
        }).then(() => {
            setBusyMessage(null);
        });
    }, [mode, type]);




    async function handleSave() {
        setBusyMessage("Saving...");
        const aadToken = await tokenManager.getAADToken();

        if (type === 'team') {
            team.leadAgent = agent;
            await saveAgentTeam(aadToken, team);
        }
        else {
            await sendAgentUpdate(aadToken, agent);
        }

        setAgent(null);
        if (onSaved)
            await onSaved();
        setBusyMessage(null);
    }

    async function handleCancel() {
        setAgent(null);
        /*setAgents(null);*/
        setBusyMessage(null);
        if (onCanceled)
            await onCanceled();
    }

    const getADGroups = useCallback(async () => {
        //async function getGraphClient() {
        //    const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(msalInstance, {
        //        account: msalInstance.getAllAccounts()[0],
        //        interactionType: InteractionType.Popup,
        //        scopes: ['User.Read', "Group.Read.All"],
        //    });

        //    const graphClient = Client.initWithMiddleware({ authProvider: authProvider });
        //    return graphClient;
        //}

        const aadToken = await tokenManager.getAADToken();

        var groupList = await getAllSecurityGroups(aadToken);
        var groups = groupList.list;
        //var groups = await getSecurityGroupsForUser(aadToken);
        const result = [];
        for (let i = 0; i < groups.length; i++) {
            if (groups[i].typeId === 1) {
                result.push(groups[i]);
            }
        }

 
        const updatedGroupsOptions = result.map((group) => ({
            key: group.uniqueId,
            text: group.displayName
        }));
        setGroupOptions(updatedGroupsOptions);
        
    }, [tokenManager]);


    const getAvailableAgents = useCallback(async () => {
        const aadToken = await tokenManager.getAADToken();
        const agentsResponse = await getAgents(aadToken);
        if (agentsResponse) {

            setAvailableAgents(agentsResponse.map(agent => ({ label: agent.text, value: parseInt(agent.value, 10) })));
        }
    }, [tokenManager]);

    useEffect(() => {
        if (type === 'team' && visible) {
            setLoading(true);
            getAvailableAgents().then(() => setLoading(false));
        }
    }, [mode, getAvailableAgents, visible, type]);

    useEffect(() => {
        if (agentId && visible === true) {
            const fetchVersions = async () => {
                const aadToken = await tokenManager.getAADToken();
                const versions = await getAgentVersions(aadToken, agentId);
                setVersions(versions);
            };
            fetchVersions();
        }
    }, [agentId, tokenManager, visible]);

    useEffect(() => {
        if (visible && !loading && agent == null) {
            loadAgentForEditing(tokenManager, agentId, setAgent, setInferenceOptions, setLoading).then(() => {
                getADGroups();
                setLoading(false);
            });
        }
    }, [getADGroups, loadAgentForEditing, loading, agent, agentId, tokenManager, visible])


    useEffect(() => {
        if (visible && !loading && agent == null) {
            setLoading(true);
        }
    }, [loading, visible, agent]);


    const handleNestedObjectChange = (prevState, nameParts, value) => {
        const [firstKey, secondKey, thirdKey] = nameParts;

        const oldFirstLevelValue = prevState[firstKey] || {};
        const oldSecondLevelValue = oldFirstLevelValue[secondKey] || {};

        const newSecondLevelValue = { ...oldSecondLevelValue, [thirdKey]: value };
        const newFirstLevelValue = { ...oldFirstLevelValue, [secondKey]: newSecondLevelValue };

        return { ...prevState, [firstKey]: newFirstLevelValue };
    };



    const handleChange = (e) => {
        const { name, value } = e.target;
        const nameParts = name.split('.');

        const cursorPosition = e.target.selectionStart;

        setAgent(prevState => {
            const updatedState = { ...prevState };
            let current = updatedState;

            for (let i = 0; i < nameParts.length - 1; i++) {
                if (!current[nameParts[i]]) current[nameParts[i]] = {};
                current = current[nameParts[i]];
            }
            current[nameParts[nameParts.length - 1]] = value;
            return updatedState;
        });

        if (inputRefs.current[name]) {
            inputRefs.current[name].selectionStart = cursorPosition;
            inputRefs.current[name].selectionEnd = cursorPosition;
        }
    };

    const handleTeamChange = (e) => {
        const { name, value } = e.target;
        const nameParts = name.split('.');


        // Save current cursor position  
        const cursorPosition = e.target.selectionStart;

        if (nameParts.length === 3) {
            setTeam(prevState => handleNestedObjectChange(prevState, nameParts, value));
        }
        else {
            setTeam(prevState => ({
                ...prevState,
                [nameParts[0]]: value
            }));
        }

        if (inputRefs.current[name]) {
            inputRefs.current[name].selectionStart = cursorPosition;
            inputRefs.current[name].selectionEnd = cursorPosition;
        }
    };

    const handleAgentSelectChange = (value, name) => {
        const newAgent = { ...agent };
        set(newAgent, name.replace(/\.\d+\./g, '.'), value);
        setAgent(newAgent);
    };

    const handleTeamSelectChange = (value, name) => {
        setTeam(prevAgent => ({
            ...prevAgent,
            [name]: value,
        }));
    };

    const handleToggle = (e) => {
        const { name, checked } = e.target;
        const nameParts = name.split('.');

        setAgent(prevState => {
            const updatedState = { ...prevState };
            let current = updatedState;

            for (let i = 0; i < nameParts.length - 1; i++) {
                if (!current[nameParts[i]]) current[nameParts[i]] = {};
                current = current[nameParts[i]];
            }
            current[nameParts[nameParts.length - 1]] = checked;
            return updatedState;
        });
    };

    const handleTeamToggle = (e) => {
        const { name, checked } = e.target;
        setTeam(prevAgent => ({
            ...prevAgent,
            [name]: checked,
        }));
    }

    const handleNumberChange = (value, name) => {
        const newAgent = { ...agent };
        set(newAgent, name.replace(/\.\d+\./g, '.'), value);
        setAgent(newAgent);
    };

    const handleModelChange = (value, name) => {
        const newAgent = { ...agent };
        set(newAgent, name.replace(/\.\d+\./g, '.'), value);
        setAgent(newAgent);
    };

    const handleReviewModelChange = (value, name) => {
        const newTeam = { ...team };
        set(newTeam, name.replace(/\.\d+\./g, '.'), value);
        setTeam(newTeam);
    };

    const handleAdminGroupsMultiSelectChange = (value, option, options) => {
        setAgent(prevState => ({
            ...prevState,
            adminGroups: value
        }));
    };

    const handleAccessGroupsMultiSelectChange = (value, option, options) => {
        setAgent(prevState => ({
            ...prevState,
            approvedGroups: value
        }));
    };

    function exportToJsonFile() {

        //convert the agent object to a string indented with 4 spaces
        let dataStr = JSON.stringify(agent, null, 4);
        let dataUri = 'data:application/json;charset=utf-8,' + encodeURIComponent(dataStr);


        let exportFileDefaultName = `${agent.name}.json`;
        if (selectedVersion !== null)
            exportFileDefaultName = `${agent.name}_V${selectedVersion}.json`;

        let linkElement = document.createElement('a');
        linkElement.setAttribute('href', dataUri);
        linkElement.setAttribute('download', exportFileDefaultName);
        linkElement.click();
    }


    const props = {
        name: 'file',
        action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
        headers: {
            authorization: 'authorization-text',
        },

        beforeUpload(file) {
            return new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsText(file);
                reader.onload = () => {
                    var id = agent.Id;
                    var loadedAgent = JSON.parse(reader.result);
                    loadedAgent.Id = id;
                    setAgent(loadedAgent);
                    resolve(false);
                };
                reader.onerror = () => {
                    console.error('File reading has failed');
                    resolve(false);
                };
            });
        },
    }


    return (
        <div>
            {(visible && agent) &&
                <Modal
                    title={`${agent.name} - ${type === 'team' ? 'Team Configuration' : 'Configuration'}`}
                    open={visible}

                    okText="Save"
                    cancelText="Cancel"
                    className="agent-configuration"
                    width="80%"
                    footer={[
                        <Button key="cancel" onClick={handleCancel}>
                            Cancel
                        </Button>,
                        <Button
                            type="primary"
                            key="save"
                            onClick={handleSave}
                            disabled={isReadOnly} // Disable the save button when isReadOnly is true
                        >
                            Save
                        </Button>
                    ]}
                >


                    {busyMessage && <Spin spinning={!!busyMessage} tip={busyMessage} ><div /></Spin>}
                    {!busyMessage && agent &&
                        <Form>
                            <Space direction="vertical" size="small" style={{ display: 'flex' }}>
                                {/* Agent details */}
                                {mode === 'add' &&
                                    <label>
                                        Name:
                                        <Input name="name" value={agent.name} onChange={handleChange} />
                                    </label>
                                }
                                {agent && versions && versions.length > 0 && (
                                    <Form.Item label="Versions">
                                        <Select
                                            value={selectedVersion}
                                            onChange={(value) => {
                                                setSelectedVersion(value);
                                                loadAgentForEditing(tokenManager, agentId, setAgent, setInferenceOptions, setLoading, value);
                                            }}
                                            style={{ width: 200 }}
                                        >
                                            <Select.Option value={null}>Latest</Select.Option>
                                            {versions.map((version) => (
                                                <Select.Option key={version} value={version}>
                                                    Version {version}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                        {isReadOnly && (
                                            <Button
                                                type="primary"
                                                onClick={() => {
                                                    setIsReadOnly(false);
                                                    setSelectedVersion(null);
                                                }}
                                            >
                                                Revert to this Version
                                            </Button>
                                        )}
                                    </Form.Item>
                                )}
                                <label>
                                    Description:
                                    <Input name="description" value={agent.description} onChange={handleChange} disabled={isReadOnly} />

                                </label>
                                <div>
                                    <label>
                                        Agent Prompt:
                                        <TextArea name="prompt" value={agent.prompt} onChange={handleChange} rows={4} disabled={isReadOnly} />
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        Inference Engine:
                                        <Select name="inference" value={agent.inference} options={inferenceOptions} style={{ width: 200 }} onChange={(value) => handleModelChange(value, 'inference')} disabled={isReadOnly} />
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        Groups With Admin Access (no selection means all groups have access):
                                        <ADGroupsDropdown name="adminGroups" onChange={handleAdminGroupsMultiSelectChange} options={groupOptions} value={agent.adminGroups} disabled={isReadOnly} />
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        Groups With Access (no selection means all groups have access):
                                        <ADGroupsDropdown name="approvedGroups" onChange={handleAccessGroupsMultiSelectChange} options={groupOptions} value={agent.approvedGroups} disabled={isReadOnly} />
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        Monthly Cost Limit:
                                        <InputNumber name="monthlyCostLimit" value={agent.monthlyCostLimit} onChange={(value) => handleNumberChange(value, 'monthlyCostLimit')} formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')} min={0} disabled={isReadOnly} />
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        Chat Cost Limit:
                                        <InputNumber name="chatCostLimit" value={agent.chatCostLimit} onChange={(value) => handleNumberChange(value, 'chatCostLimit')} formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')} min={0} disabled={isReadOnly} />
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        Chat History Retention In Days:
                                        <InputNumber name="chatHistoryRetentionInDays" value={agent.chatHistoryRetentionInDays} onChange={(value) => handleNumberChange(value, 'chatHistoryRetentionInDays')} min={0}
                                            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')} disabled={isReadOnly} />
                                    </label>
                                </div>
                                <label>
                                    Image Folder Name:
                                    <Input name="imageFolderName" value={agent.imageFolderName} onChange={handleChange} disabled={isReadOnly} />
                                </label>
                                <label>
                                    Welcome Message:
                                    <Input name="welcomeMessage" value={agent.welcomeMessage} onChange={handleChange} disabled={isReadOnly} />
                                </label>
                                <label>
                                    <Checkbox name="hideDisabledTools" checked={agent.hideDisabledTools} onChange={handleToggle} disabled={isReadOnly}>
                                        Hide Disabled Tools
                                    </Checkbox>
                                </label>


                                {type === 'team' ? (

                                    <div>
                                        <h2>Team Settings</h2>
                                        <div>
                                            <label>
                                                Review Prompt:
                                                <TextArea name="reviewPrompt" value={team.reviewPrompt} onChange={handleTeamChange} rows={4} disabled={isReadOnly} />
                                            </label>
                                        </div>
                                        <div>
                                            <label>
                                                Review Inference Engine:
                                                <Select name="reviewInference" value={team.reviewInference} options={inferenceOptions} style={{ width: 200 }} onChange={(value) => handleReviewModelChange(value, 'reviewInference')} disabled={isReadOnly} />
                                            </label>
                                        </div>
                                        <div>
                                            <label>
                                                Select Agents:
                                                <Select
                                                    mode="multiple"
                                                    options={availableAgents}
                                                    value={team.agentIds}
                                                    onChange={(value) => handleTeamSelectChange(value, 'agentIds')}
                                                    style={{ width: '100%' }}
                                                    disabled={isReadOnly}
                                                />
                                            </label>
                                        </div>
                                        <div>
                                            <label>
                                                <Checkbox name="userApprovePlan" checked={team.userApprovePlan} onChange={handleTeamToggle} disabled={isReadOnly}>
                                                    User must approve plan
                                                </Checkbox>
                                            </label>
                                        </div>
                                    </div>
                                ) : (
                                    // Agent specific UI  
                                    <>
                                        {/* Include Agent-specific fields here */}
                                    </>
                                )}

                                <h2>Tool Settings</h2>
                                {/* Render tools */}
                                {agent.tools && Object.entries(agent.tools).sort((a, b) => parseInt(a[1].displayOrder) - parseInt(b[1].displayOrder)).map(([toolKey, tool], i) => (
                                    <ToolConfigPanel
                                        title={toolKey}
                                        key={`tools.${toolKey}`}
                                        toolKey={`tools.${toolKey}`}
                                        tool={tool}
                                        hideDisabledTools={agent.hideDisabledTools}
                                        handleChange={handleChange}
                                        handleToggle={handleToggle}
                                        handleNumberChange={handleNumberChange}
                                        handleModelChange={handleModelChange}
                                        handleSelectChange={handleAgentSelectChange}
                                        inferenceOptions={inferenceOptions}
                                        inputRefs={inputRefs}
                                        disabled={isReadOnly}
                                        isReadOnly={isReadOnly}
                                    />
                                ))}


                                <div>
                                    <Space direction="horizontal" size="middle" style={{ display: 'flex' }}>
                                        <label>
                                            Show Chat Cost:&nbsp;
                                            <Checkbox name="showChatCost" checked={agent.showChatCost} onChange={handleToggle} disabled={isReadOnly} />
                                        </label>
                                        <label>
                                            Show Debug Info:&nbsp;
                                            <Checkbox name="showDebugInfo" checked={agent.showDebugInfo} onChange={handleToggle} disabled={isReadOnly} />
                                        </label>
                                    </Space>
                                </div>
                                <div>
                                    <Space direction="horizontal" size="middle" style={{ display: 'flex' }}>
                                        <Button onClick={exportToJsonFile}>Export As JSON</Button>
                                        <Upload {...props}>
                                            <Button icon={<UploadOutlined />}>Upload Json Agent Config</Button>
                                        </Upload>
                                    </Space>
                                </div>
                            </Space>

                        </Form>
                    }

                </Modal>
            }
        </div >
    )
}

export default AgentConfigurator;

