import React, { useState, useEffect, useCallback } from 'react';
import { Drawer,  Select, Table } from 'antd';
import { getListDataFromAPI, getListsFromAPI } from '../messaging'


const TaskListPanel = ({ connection, chatId, tokenManager, visible }) => {
    const [isDialogVisible, setIsDialogVisible] = useState(false);
    const [selectedListId, setSelectedListId] = useState(null);
    const [listData, setListData] = useState([]);
    const [availableLists, setAvailableLists] = useState([]);
    const [approvedByUser, setApprovedByUser] = useState(null);  

    useEffect(() => {
        setIsDialogVisible(visible);
    }, [visible]);

    //function to convert object properties to pascal case
    const convertObjectPropertiesToCamelCase = useCallback((obj) => {
        for (var property in obj) {
            if (obj.hasOwnProperty(property)) {
                var newProperty = toCamelCase(property);
                if (newProperty !== property) {
                    obj[newProperty] = obj[property];
                    delete obj[property];
                }
                if (typeof obj[newProperty] === 'object') {
                    convertObjectPropertiesToCamelCase(obj[newProperty]);
                }
            }
        }
    }, []);

    useEffect(() => {
        if (!connection) return;

        const handleUpdateTaskList = async (chatId, newTaskList) => {
            convertObjectPropertiesToCamelCase(newTaskList);
            const aadToken = await tokenManager.getAADToken();
            const lists = await getListsFromAPI(aadToken, chatId);
            setAvailableLists(lists);

            if (listData !== undefined && (newTaskList.id === listData.id)) {
                setListData(newTaskList);
                setApprovedByUser(newTaskList.approvedByUser); 
            }
        };

        connection.on('UpdateTaskList', handleUpdateTaskList);

        return () => {
            connection.off('UpdateTaskList', handleUpdateTaskList);
        };
    }, [connection, convertObjectPropertiesToCamelCase, listData, tokenManager]);

    //function to change the josn to pascal case 
    function toCamelCase(str) {
        return str.replace(/(\w)(\w*)/g,
            function (g0, g1, g2) { return g1.toLowerCase() + g2; });
    }



    // Memoize the handleListChange function  
    const handleListChange = useCallback(
        async (id) => {
            const aadToken = await tokenManager.getAADToken();
            setSelectedListId(id);
            var data = await getListDataFromAPI(aadToken, chatId, id);            
            setListData(data);
            setApprovedByUser(data.approvedByUser); 
        },
        [tokenManager, chatId] // Ensure these dependencies are stable  
    );  


    useEffect(() => {
        
        const fetchAvailableLists = async () => {
            if (chatId !== undefined && chatId !== null) {
                const aadToken = await tokenManager.getAADToken();
                const lists = await getListsFromAPI(aadToken, chatId);
                setAvailableLists(lists);
            }
        }

        fetchAvailableLists();
    }, [chatId, tokenManager, isDialogVisible]);


    const columns = [
        {
            title: 'Task Number',
            dataIndex: 'taskNumber',
            key: 'taskNumber',
        },
        {
            title: 'Description',
            dataIndex: 'taskDescription',
            key: 'taskDescription',
        },
        {
            title: 'Due Date',
            dataIndex: 'taskDueDate',
            key: 'taskDueDate',
            render: (text) => (text ? new Date(text).toLocaleDateString() : ''),
        },
        {
            title: 'Priority',
            dataIndex: 'taskPriority',
            key: 'taskPriority',
        },
        {
            title: 'Status',
            dataIndex: 'taskStatus',
            key: 'taskStatus',
        },
        {
            title: 'Location',
            dataIndex: 'taskLocation',
            key: 'taskLocation',
        },
        {
            title: 'Result',
            dataIndex: 'taskResult',
            key: 'taskResult',
        },
        {
            title: 'Assigned To',
            dataIndex: 'assignedTo',
            key: 'assignedTo',
        },
        {
            title: 'Dependent On Output From',
            dataIndex: 'dependentOnOutputFrom',
            key: 'dependentOnOutputFrom',
            render: (list) => (list && list.length ? list.join(', ') : ''),
        },
        {
            title: 'Execution Order',
            dataIndex: 'executionOrder',
            key: 'executionOrder',
        },
        {
            title: 'Attempt Number',
            dataIndex: 'attemptNumber',
            key: 'attemptNumber',
        },
    ];

    return (
        <div>
            {/*<FloatButton onClick={() => setIsDialogVisible(true)} style={{ top: 16, right: 16, bottom: 'auto' }} shape="square" visible={!isDialogVisible}*/}
            {/*    icon={<OrderedListOutlined />}*/}
            {/*/>*/}

            <Drawer
                title="List Viewer"
                placement="right"
                onClose={() => setIsDialogVisible(false)}
                visible={isDialogVisible}
                mask={false}
                getContainer={false}
                style={{ position: 'absolute' }} //{{ position: isDocked ? 'fixed' : 'absolute' }}
                width={600}
            >
                <Select
                    value={selectedListId}
                    placeholder="Select a list"
                    onChange={handleListChange}
                    style={{ width: '100%', marginBottom: 16 }}
                >
                    {availableLists.map((list) => (
                        <Select.Option key={list.id} value={list.id}>
                            {list.title}
                        </Select.Option>
                    ))}
                </Select>

                {/* Display the approval status */}
                {approvedByUser !== null && (
                    <div style={{ marginBottom: 16, color: 'black' }}>
                        <p>
                            <strong>Approval Status:</strong> {approvedByUser ? 'Approved' : 'Not Approved'}
                        </p>
                    </div>
                )} 

                <Table dataSource={listData.tasks} columns={columns} rowKey="id" />
            </Drawer>
        </div>
    );
};  

export default TaskListPanel;  