import React, {
    useState,
    useCallback,
    useMemo,    
    forwardRef,
    useImperativeHandle,
    useEffect,
} from "react";
import { Select } from "antd";
import { debounce } from "lodash";
import { searchUsers } from "../messaging";

function UserSearchComboInner({ tokenManager, onUserSelected }, ref) {
    const [searchValue, setSearchValue] = useState("");
    const [options, setOptions] = useState([]);
    const [fetching, setFetching] = useState(false);

    useImperativeHandle(ref, () => ({
        resetInput: () => {
            setSearchValue("");
            setOptions([]);
        },
    }));

    // 1) The real search callback, which depends on tokenManager
    const fetchUserOptions = useCallback(
        async (value) => {
            if (!value) {
                setOptions([]);
                return;
            }
            setFetching(true);
            try {
                const aadToken = await tokenManager.getAADToken();
                const data = await searchUsers(aadToken, value);
                if (data) {
                    const newOptions = data.map((user) => ({
                        label: `${ user.displayName }(${ user.mail || user.id })`,
                        value: user.id,
}));
    setOptions(newOptions);
}
} catch (error) {
    console.error("Error searching users:", error);
    setOptions([]);
} finally {
    setFetching(false);
}
},
[tokenManager]
);

// 2) A memoized �debounced� function that calls your search callback
const doSearch = useMemo(
    () => debounce(fetchUserOptions, 500),
    [fetchUserOptions]
);

// (Optional) Clean up the debounced function on unmount
useEffect(() => doSearch.cancel, [doSearch]);

const handleSearch = (value) => {
    setSearchValue(value);
    doSearch(value);
};

const handleChange = (value, option) => {
    setSearchValue(option.label);
    onUserSelected?.(value, option);
};

return (
    <Select
        showSearch
        value={searchValue}
        placeholder="Search for a user..."
        notFoundContent={fetching ? null : "Not found"}
        filterOption={false}
        onSearch={handleSearch}
        onChange={handleChange}
        options={options}
        style={{ width: 300 }}
    />
);
}

const UserSearchCombo = forwardRef(UserSearchComboInner);
export default UserSearchCombo;