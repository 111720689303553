import { Input, Collapse, Avatar, Form, Checkbox, InputNumber, Select } from 'antd';
import React, { useRef, useEffect } from 'react';
import get from 'lodash/get';

const { TextArea } = Input;
const { Option } = Select;

const ToolConfigPanel = React.memo(function ToolConfigPanel({
    title,
    toolKey,
    tool,
    handleChange,
    handleToggle,
    handleNumberChange,
    handleModelChange,
    handleSelectChange,
    inferenceOptions,
    inputRefs,
    depth = 0,
    hideDisabledTools,
    isReadOnly
}) {
    if (hideDisabledTools && tool.disabled) {
        return null; // Do not render this tool  
    }
    const header = (
        <span>
            {tool.iconUrl && <Avatar src={tool.iconUrl} style={{ marginRight: 8, height: 80, width: 80 }} />}
            {title}
        </span>
    );
    return (
        <Collapse
            defaultActiveKey={[tool.id || toolKey]}
            expandIconPosition="end"
            style={{ marginLeft: depth * 20 }}
        >
            <Collapse.Panel header={header} key={tool.id || toolKey}>
                {/* Render description if available */}
                {tool.description && (
                    <p>{tool.description}</p>
                )}
                {/* Render controls */}
                {tool.designtimeControlConfig &&
                    [...tool.designtimeControlConfig]
                        .sort((a, b) => a.displayOrder - b.displayOrder)
                        .map((designTimeControlConfig, i) => (
                            <div key={designTimeControlConfig.name}>
                                {!designTimeControlConfig.invisible &&
                                    <RenderControl
                                        designTimeControlConfig={designTimeControlConfig}
                                        keyPath={toolKey}
                                        tool={tool}
                                    inputRefs={inputRefs}
                                        isReadOnly={isReadOnly}
                                        handlers={{
                                            handleChange,
                                            handleToggle,
                                            handleNumberChange,
                                            handleModelChange,
                                            handleSelectChange,
                                            inferenceOptions,
                                        }}
                                    />
                                }
                            </div>
                        ))}
                {/* Recursively render child tools */}
                {tool.childTools && tool.childTools.length > 0 && (
                    <div style={{ marginLeft: depth * 20 }}>
                        {tool.childTools.map((childTool, index) => (
                            <ToolConfigPanel
                                key={childTool.id || `${toolKey}-${index}`}
                                toolKey={`${toolKey}.childTools[${index}]`}
                                tool={childTool}
                                title={childTool.name || `Tool ${index + 1}`}
                                handleChange={handleChange}
                                handleToggle={handleToggle}
                                handleNumberChange={handleNumberChange}
                                handleModelChange={handleModelChange}
                                inferenceOptions={inferenceOptions}
                                inputRefs={inputRefs}
                                handleSelectChange={handleSelectChange}
                                depth={depth + 1}
                                hideDisabledTools={hideDisabledTools}
                                isReadOnly={isReadOnly}
                            />
                        ))}
                    </div>
                )}
            </Collapse.Panel>
        </Collapse>
    );
});

function RenderControl({ designTimeControlConfig, keyPath, tool, inputRefs, handlers, isReadOnly }) {
    const name = `${keyPath}.${designTimeControlConfig.name}`;
    const inputRef = useRef(null);

    useEffect(() => {
        if (inputRef.current) {
            inputRefs.current[name] = inputRef.current;
        }
    }, [name, inputRefs]);

    switch (designTimeControlConfig.type) {
        case "MultilineEdit":
            return (
                <Form.Item label={designTimeControlConfig.text}>
                    <TextArea
                        ref={inputRef}
                        name={name}
                        value={get(tool, designTimeControlConfig.name)}
                        onChange={handlers.handleChange}
                        rows={4}
                        disabled={isReadOnly}
                    />
                </Form.Item>
            );
        case "Checkbox":
            return (
                <Form.Item>
                    <Checkbox
                        name={name}
                        checked={get(tool, designTimeControlConfig.name)}
                        onChange={handlers.handleToggle}
                        disabled={isReadOnly}
                    >
                        {designTimeControlConfig.text}
                    </Checkbox>
                </Form.Item>
            );
        case "Number":
            return (
                <Form.Item label={designTimeControlConfig.text}>
                    <InputNumber
                        name={name}
                        value={get(tool, designTimeControlConfig.name)}
                        min={0}
                        max={1000}
                        onChange={(value) => handlers.handleNumberChange(value, name)}
                        disabled={isReadOnly}
                    />
                </Form.Item>
            );
        case "SinglelineEdit":
            return (
                <Form.Item label={designTimeControlConfig.text}>
                    <Input
                        name={name}
                        value={get(tool, designTimeControlConfig.name)}
                        onChange={handlers.handleChange}
                        disabled={isReadOnly}
                    />
                </Form.Item>
            );
        case "Password":
            return (
                <Form.Item label={designTimeControlConfig.text}>
                    <Input.Password
                        name={name}
                        value={get(tool, designTimeControlConfig.name)}
                        onChange={handlers.handleChange}
                        disabled={isReadOnly}
                        visibilityToggle={false}
                        placeholder="input password"
                    />
                </Form.Item>
            );
        case "InferenceModelDropList":
            return (
                <Form.Item label={designTimeControlConfig.text} key={name}>
                    <Select
                        name={name}
                        options={handlers.inferenceOptions}
                        value={get(tool, designTimeControlConfig.name)}
                        style={{ width: 200 }}
                        onChange={(value) => handlers.handleModelChange(value, name)}
                        disabled={isReadOnly}
                    />
                </Form.Item>
            );
        case "SearchMode":
            return (
                <Form.Item label={designTimeControlConfig.text}>
                    <Select
                        name={name}
                        value={get(tool, designTimeControlConfig.name)}
                        style={{ width: 200 }}
                        onChange={(value) => handlers.handleSelectChange(value, name)}
                        defaultValue="Any"
                        disabled={isReadOnly}
                    >
                        <Option value="All">All</Option>
                        <Option value="Any">Any</Option>
                    </Select>
                </Form.Item>
            );
        case "SearchProvider":
            return (
                <Form.Item label={designTimeControlConfig.text}>
                    <Select
                        name={name}
                        value={get(tool, designTimeControlConfig.name)}
                        style={{ width: 200 }}
                        onChange={(value) => handlers.handleSelectChange(value, name)}
                        disabled={isReadOnly}
                    >
                        <Option value="Cognitive">Cognitive Search</Option>
                        <Option value="Elastic">Elastic Search</Option>
                    </Select>
                </Form.Item>
            );
        default:
            return null;
    }
}

export default ToolConfigPanel;  