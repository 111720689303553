
import React, { useEffect, useState, useCallback, useRef } from 'react';
import {
    Modal,
    Table,
    Button,
    Popconfirm,
    Form,
    Input,
    List,
    Select
} from 'antd';
import {
    getAllSecurityGroups,
    getSecurityGroup,
    createSecurityGroup,
    updateSecurityGroup,
    deleteSecurityGroup,
    addUserToGroup,
    removeUserFromGroup,
} from '../messaging'; // adjust path as needed

import UserSearchCombo from './UserSearchCombo';

export default function SecurityGroupManagerModal({
    visible,
    onCancel,
    tokenManager,
}) {
    const [groups, setGroups] = useState([]);
    const [groupTypes, setGroupTypes] = useState([]);
    const [loading, setLoading] = useState(false);

    // Sub-modal for creating/editing a single group  
    const [isCrudModalVisible, setCrudModalVisible] = useState(false);
    const [editingGroup, setEditingGroup] = useState(null);
    const [form] = Form.useForm();

    // For user management  
    const [groupUsers, setGroupUsers] = useState([]);
    const [newUserId, setNewUserId] = useState('');
    const userSearchRef = useRef();

    // Load all groups  
    const loadGroups = useCallback(async () => {
        try {
            setLoading(true);
            const token = await tokenManager.getAADToken();
            const data = await getAllSecurityGroups(token);
            setGroups(data.list);
            setGroupTypes(data.groupTypes);
        } catch (err) {
            console.error('Error fetching security groups:', err);
        } finally {
            setLoading(false);
        }
    }, [tokenManager]);

    // Reload groups when the big modal is opened  
    useEffect(() => {
        if (visible) {
            loadGroups();
            // Reset local selection/state  
            setEditingGroup(null);
            setGroupUsers([]);
        }
    }, [visible, loadGroups]);

    // Open sub-modal to create a new group  
    const showCreateModal = () => {
        setEditingGroup(null);
        form.resetFields();
        setCrudModalVisible(true);
    };

    // Open sub-modal to edit an existing group  
    const showEditModal = async (record) => {
        try {
            setLoading(true);
            const token = await tokenManager.getAADToken();
            const fullGroup = await getSecurityGroup(token, record.id);

            setEditingGroup(fullGroup);
            form.setFieldsValue({
                id: fullGroup.id,
                name: fullGroup.name,
                description: fullGroup.description,
                groupType: fullGroup.groupType,
            });

            // If getSecurityGroup also returns users:  
            // setGroupUsers(fullGroup.securityGroupUsers || []);  

            setCrudModalVisible(true);
        } catch (err) {
            console.error('Error loading group details:', err);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (record) => {
        try {
            setLoading(true);
            const token = await tokenManager.getAADToken();
            await deleteSecurityGroup(token, record.id);
            loadGroups();
        } catch (err) {
            console.error('Error deleting group:', err);
        } finally {
            setLoading(false);
        }
    };

    // Handle submission in the create/edit sub-modal  
    const onCrudModalOk = async () => {
        const values = form.getFieldsValue();
        try {
            setLoading(true);
            const token = await tokenManager.getAADToken();

            if (!editingGroup) {
                // Creating a new group  
                await createSecurityGroup(token, {
                    displayName: values.name,
                    description: values.description,
                    typeId: values.groupTypeId,
                });
            } else {
                // Updating an existing group  
                await updateSecurityGroup(token, {
                    id: editingGroup.id,
                    displayName: values.name,
                    description: values.description,
                    typeId: values.groupTypeId,
                });
            }

            setCrudModalVisible(false);
            loadGroups();
        } catch (err) {
            console.error('Error creating/updating group:', err);
        } finally {
            setLoading(false);
        }
    };

    // Add user to group  
    const handleAddUser = async (record) => {
        if (!newUserId) return;
        try {
            setLoading(true);
            const token = await tokenManager.getAADToken();
            await addUserToGroup(token, record.id, newUserId);
            setNewUserId('');
            // Optionally refresh user list  
        } catch (err) {
            console.error('Error adding user to group:', err);
        } finally {
            setLoading(false);
        }
    };

    // Remove user from group  
    const handleRemoveUser = async (record, userId) => {
        try {
            setLoading(true);
            const token = await tokenManager.getAADToken();
            await removeUserFromGroup(token, record.id, userId);
            // Optionally refresh user list  
        } catch (err) {
            console.error('Error removing user from group:', err);
        } finally {
            setLoading(false);
        }
    };

    const loadGroupUsers = async (groupId) => {
        try {
            setLoading(true);
            const token = await tokenManager.getAADToken();
            const fullGroup = await getSecurityGroup(token, groupId);
            setEditingGroup(fullGroup); // if you also want the rest of the group data
            setGroupUsers(fullGroup.securityGroupUsers || []);
        } catch (err) {
            console.error("Error fetching group users:", err);
        } finally {
            setLoading(false);
        }
    };

    async function handleClose() {
        onCancel();
    }


    // Columns for the groups table  
    const columns = [
        {
            title: 'Name',
            dataIndex: 'displayName',
        },
        {
            title: 'Description',
            dataIndex: 'description',
        },
        {
            title: 'Type',
            render: (text, record) => (

                groupTypes.find(gt => gt.id === record.typeId)?.name


            ),
        },
        {
            title: 'Actions',
            render: (text, record) => (
                <div style={{ display: 'flex', gap: 8 }}>
                    <Button onClick={() => showEditModal(record)} disabled={record.typeId === 0}>Edit</Button>
                    
                    <Popconfirm
                        title="Are you sure you want to delete this group?"
                        onConfirm={() => handleDelete(record)}
                    >
                        <Button disabled={ record.typeId===0 } >Delete</Button>
                    </Popconfirm>
                    {/* Manage users inline by selecting the group */}
                    <Button onClick={() => {
                        setEditingGroup(record);
                        loadGroupUsers(record.id);
                    }
                    }>Manage Users</Button>
                </div>
            ),
        },
    ];

    return (
        <Modal
            title="Manage Security Groups"
            visible={visible}
            onCancel={handleClose}
            footer={[
                <Button
                    type="primary"
                    key="save"
                    onClick={handleClose}                    
                >
                    Close
                </Button>
            ]}
            width={1000}
        >
            {/* Create button */}
            <div style={{ marginBottom: 16 }}>
                <Button type="default" onClick={showCreateModal}>
                    Create Group
                </Button>
            </div>

            {/* Table of groups */}
            <Table
                dataSource={groups}
                columns={columns}
                loading={loading}
                rowKey="id"
            />

            {/* User management section (visible if a group is selected) */}
            {editingGroup && (
                <div style={{ marginTop: 24 }}>
                    <h3>Users in Group: {editingGroup.name}</h3>
                    <div style={{ display: 'flex', gap: 8, marginBottom: 16 }}>
                        <UserSearchCombo tokenManager={tokenManager} onUserSelected={(value, option) => setNewUserId(value)} ref={userSearchRef} />
                        <Button onClick={async () => {
                            await handleAddUser(editingGroup);
                            await loadGroupUsers(editingGroup.id);
                            userSearchRef.current?.resetInput();
                        }}>
                            Add user
                        </Button>
                    </div>

                    {groupUsers && groupUsers.length > 0 && (
                        <List
                            dataSource={groupUsers}
                            renderItem={(user) => (
                                <List.Item
                                    actions={[
                                        <Button
                                            onClick={async () => {
                                                await handleRemoveUser(editingGroup, user.userId);
                                                await loadGroupUsers(editingGroup.id);
                                            }
                                            }
                                        >
                                            Remove
                                        </Button>,
                                    ]}
                                >
                                    {user.name} ({user.email})
                                </List.Item>
                            )}
                        />
                    )}
                </div>
            )}

            {/* Sub-modal for creating/editing a group */}
            <Modal
                title={editingGroup ? 'Edit Group' : 'Create Group'}
                visible={isCrudModalVisible}
                onOk={onCrudModalOk}
                confirmLoading={loading}
                onCancel={() => setCrudModalVisible(false)}
            >
                <Form form={form} layout="vertical">
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[{ required: true, message: 'Group name is required' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item label="Description" name="description">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Group Type" name="groupTypeId">
                        <Select placeholder="Select a type">
                            {groupTypes?.filter(gt => gt.id!==0)?.map(gt => (
                                <Select.Option key={gt.id} value={gt.id}>
                                    {gt.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item> 
                </Form>
            </Modal>
        </Modal>
    );
}  