import { InteractionRequiredAuthError } from "@azure/msal-browser";
import ConfigurationManager from './components/ConfigurationManager';
import axios from 'axios';
import Cookie from 'universal-cookie';
import { jwtDecode } from 'jwt-decode'

class TokenManager {
    constructor(instance, accounts) {
        this.msalInstance = instance;
        this.tokenCache = null;
        this.tokenExpiry = null;
        this.expiresInMs = 10 * 60 * 1000; 
        this.accounts = accounts;               
    }

   

    isTokenExpired() {
        if (!this.tokenExpiry)
            return true;

        const now = new Date().getTime();
        return now >= this.tokenExpiry;
    }

    async expireCachedToken() {
        this.tokenExpiry = null;
    }

    async getAADToken() {
        if (this.tokenCache && !this.isTokenExpired()) {
            return this.tokenCache;
        }

        const silentRequest = {
            scopes: ["api://7b8b43c8-339e-41de-92e8-b778e8e75381/Engine.Inference", "User.Read", "Group.Read.All"],
            account: this.accounts[0]
        };


        

        //If there is no active account, use needs to pick one
        //if (accounts[0] === null || accounts[0] === undefined) {
        //    const response = await this.msalInstance.acquireTokenPopup(silentRequest);
        //    this.tokenCache = response.accessToken;
        //    this.tokenExpiry = new Date().getTime() + this.expiresInMs - (10 * 60 * 1000);
        //    return this.tokenCache;
        //}
        //else {
            try {
                const response = await this.msalInstance.acquireTokenSilent(silentRequest);
                this.tokenCache = response.accessToken;

                // Set the expiry time (50 minutes)            
                this.tokenExpiry = new Date().getTime() + this.expiresInMs; 

                return this.tokenCache;
            } catch (error) {
                if (error instanceof InteractionRequiredAuthError) {
                    const response = await this.msalInstance.acquireTokenPopup(silentRequest);
                    this.tokenCache = response.accessToken;

                    this.tokenExpiry = new Date().getTime() + this.expiresInMs;

                    return this.tokenCache;
                } else {
                    console.log(error);
                    return null;
                    //throw error;
                }
            }
        //}
    }

    async checkIfTokenContainsRequiredGroup(token, groupid) {
        if (token && typeof token == "string") {
            const decodedToken = jwtDecode(token);
            const groups = decodedToken.groups;
            for (var i = 0; i < groups.length; i++) {
                if (groups[i] === groupid) {
                    return true;
                }
            }
        }
        return false;
    }

    async getSpeechAPIToken(token) {

        if (token === null || token === undefined) {
            token = await this.getAADToken();

            if (token === null || token === undefined) {
                console.error('AAD token is null or undefined');
                return null;
            }
        }

        const cookie = new Cookie();
        const speechToken = cookie.get('speech-token');
        const realToken = token;

        if ((speechToken === undefined) ||
            ((typeof (speechToken) === 'string') && speechToken.includes('undefined'))
        ) {
            try {
                var baseUrl = ConfigurationManager.getApiUrl();

                if (process.env.REACT_APP_TEST !== undefined) {
                    baseUrl = process.env.REACT_APP_TEST;
                }

                console.log(process.env.NODE_ENV);
                console.log(process.env.REACT_APP_TEST);

                const instance = axios.create({
                    baseURL: baseUrl,
                    timeout: 15000,
                    headers: { 'Authorization': `Bearer ${realToken}` }
                });

                const res = await instance.get('/speech');
                const token = res.data.token;
                const region = res.data.region;
                cookie.set('speech-token', region + ':' + token, { maxAge: 540, path: '/' });

                //console.log('Token fetched from back-end: ' + token);
                return { authToken: token, region: region };
            } catch (err) {
                console.error(err.message);

                var errorData = null;
                if (err.response != null && err.response.data != null) {
                    console.log(err.response.data);
                    errorData = err.response.data;
                }

                alert(err.message + " connecting to:" + baseUrl);

                

                return { authToken: null, error: errorData };
            }
        } else {
            const idx = speechToken.indexOf(':');
            return { authToken: speechToken.slice(idx + 1), region: speechToken.slice(0, idx) };
        }
    }
}

export default TokenManager;

